export default {
  openEditServiceTypesPositionModal({
    dispatch, commit,
  }, {
    id,
  }) {
    commit('SET_IS_SHOWN', true);
    dispatch('setNetworksId', id);
    dispatch('getServiceTypesList', id);
  },
  openEditServicesPositionModal({
    dispatch, commit,
  }, {
    id,
  }) {
    commit('SET_IS_SHOWN_SERVICES', true);
    dispatch('setNetworksId', id);
    dispatch('getServiceTypesList', id);
  },
  openEditNetworksPositionModal({
    dispatch, commit,
  }, {
    id,
  }) {
    commit('SET_IS_SHOWN_NETWORKS', true);
    dispatch('setNetworksId', id);
    dispatch('getNetworksList');
  },
  openEditServiceTypeModal({
    dispatch, commit,
  }, {
    id,
    isCreated,
  }) {
    commit('SET_IS_SHOWN_SERVICE_TYPES', true);
    dispatch('setIsCreated', isCreated);
    dispatch('getNetworksList');
    dispatch('createNewTranslations');

    if (!isCreated) {
      dispatch('setServiceTypeId', id);
      dispatch('getServiceType', id);
    }
  },
  setIsCreated({ commit }, isCreated) {
    commit('SET_IS_CREATED', isCreated);
  },
  setNetworksId({ commit }, id) {
    commit('SET_NETWORK_ID', id);
  },
  setServiceTypeId({ commit }, id) {
    commit('SET_SERVICE_TYPE_ID', id);
  },
  async getServicesList({
    rootGetters, dispatch,
  }, id) {
    await rootGetters['networks/getRepository'].servicesList(id)
      .then((response) => {
        dispatch('setServices', response);
      });
  },
  async getNetworksList({
    rootGetters, dispatch,
  }) {
    await rootGetters['networks/getRepository'].list()
      .then((response) => {
        dispatch('setNetworks', response);
      });
  },
  getServiceTypesList({
    rootGetters, dispatch,
  }, id) {
    rootGetters['networks/getRepository'].serviceTypesByNetworkId(id)
      .then((response) => {
        dispatch('setServiceTypes', response);
      });
  },
  getServiceType({
    rootGetters,
    dispatch,
  }, id) {
    rootGetters['networks/getServiceTypesRepository'].getTypeByID(id)
      .then((response) => {
        dispatch('setServiceType', response);
        dispatch('createNewTranslations');
      });
  },
  setNetworks({ commit }, val) {
    commit('SET_NETWORKS', val);
  },
  setServiceTypes({ commit }, val) {
    commit('SET_SERVICE_TYPES', val);
  },
  setLoading({ commit }, val) {
    commit('SET_LOADING', val);
  },
  setServices({ commit }, response) {
    commit('SET_SERVICES', response);
  },
  setServiceType({ commit }, val) {
    commit('SET_SERVICE_TYPE', val);
  },
  saveNetworks({
    rootGetters,
    state,
    dispatch,
  }) {
    if (!state.networks.length) return;
    rootGetters['networks/getRepository'].sort(
      state.networks.map((item) => item.id),
    )
      .then(() => {
        dispatch('networks/table/fetch', null, { root: true });
        dispatch('networksModalClose');
      });
  },
  saveServiceTypes({
    rootGetters,
    state,
    dispatch,
  }) {
    if (!state.serviceTypes.length) return;
    dispatch('setLoading', true);
    rootGetters['networks/getRepository'].sortServiceTypes(
      state.networkId,
      state.serviceTypes.map((item) => item.id),
    )
      .then(() => {
        dispatch('networks/table/fetch', null, { root: true });
        dispatch('setLoading', false);
        dispatch('serviceTypesModalClose');
      });
  },
  saveServices({
    rootGetters,
    state,
    dispatch,
  }) {
    if (!state.services.length) return;
    dispatch('setLoading', true);
    rootGetters['networks/getServiceTypesRepository'].sortServices(
      state.serviceTypeId,
      state.services.map((item) => item.id),
    )
      .then(() => {
        dispatch('setLoading', false);
        dispatch('servicesModalClose');
      });
  },
  serviceTypesModalClose({
    commit,
    dispatch,
  }) {
    commit('SET_IS_SHOWN', false);
    dispatch('resetState');
  },
  servicesModalClose({
    commit,
    dispatch,
  }) {
    commit('SET_IS_SHOWN_SERVICES', false);
    dispatch('resetState');
  },
  serviceTypeModalClose({
    commit,
    dispatch,
  }) {
    commit('SET_IS_SHOWN_SERVICE_TYPES', false);
    dispatch('resetState');
  },
  networksModalClose({
    commit,
    dispatch,
  }) {
    commit('SET_IS_SHOWN_NETWORKS', false);
    dispatch('resetState');
  },
  resetState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors);
  },
  createNewTranslations({
    commit,
    rootGetters,
    state,
  }) {
    rootGetters['settings/localesValuesArray'].forEach((locale) => {
      const { value } = locale;

      if (!Object.hasOwnProperty.call(state.serviceType.translations, value)) {
        commit('SET_TRANSLATIONS', value);
      }
    });
  },
  setSelectedLocale({ commit }, val) {
    commit('SET_SELECTED_LOCALE', val);
  },
  setSelectedIcon({ commit }, val) {
    console.log(val);
    commit('SET_SELECTED_ICON', val);
  },
  updateReviewsCount({ commit }, val) {
    commit('SET_SELECTED_REVIEWS_COUNT', val);
  },
  updateReviewsRating({ commit }, val) {
    commit('SET_SELECTED_REVIEWS_RATING', val);
  },
  updateTranslations({
    commit,
  }, obj) {
    commit('UPDATE_TRANSLATIONS', obj);
  },
  updateServiceTypes({
    dispatch,
    rootGetters,
    state,
  }) {
    rootGetters['networks/getServiceTypesRepository'].update(state.serviceType)
      .then(() => {
        dispatch('serviceTypeModalClose');
        dispatch('networks/table/fetch', null, { root: true });
      });
  },
};
